import React, { useState } from 'react'
import Main from '../layouts/Main'
import { Menu, MenuItem, Intent, IconName } from '@blueprintjs/core'
import { StoreDataTable } from '../components/DataTable'
import { Routes } from '../urls/router-paths'
import { StoreData } from '../components/data'

enum Views {
  stores = 'Stores',
}

type SetupSelectView = (itemView: Views) => void

interface SetupMenuProps {
  selectView: SetupSelectView
  currentView: Views
}

interface SetupMenuItemProps {
  currentView: Views
  selectView: SetupSelectView
  itemView: Views
  icon: IconName
  title?: string
  disabled?: boolean
}

interface SetupState {
  view: Views
}

const SetupMenuItem = (props: SetupMenuItemProps) => {
  const { currentView, selectView, itemView, title, icon, disabled = false } = props
  const getIntent = (itemView: Views) => {
    return currentView === itemView ? Intent.PRIMARY : Intent.NONE
  }

  return (
    <MenuItem
      icon={icon}
      text={title ?? itemView}
      onClick={() => selectView(itemView)}
      intent={getIntent(itemView)}
      disabled={disabled}
    />
  )
}

const SetupMenu = (props: SetupMenuProps) => {
  return (
    <Menu className="sticky">
      <SetupMenuItem icon="shop" itemView={Views.stores} {...props} />
    </Menu>
  )
}

const Setup = () => {
  const [state, setState] = useState<SetupState>({ view: Views.stores })
  // Set selected view for the Setup page
  const selectView = (view: Views) => {
    setState({
      ...state,
      view: view
    })
  }

  // Get table data
  const storeData = StoreData()

  // Get the table associated with a view if possible
  const dataTable = () => {
    switch (state.view) {
      case Views.stores:
        return <StoreDataTable data={storeData} />
      default:
        return <div />
    }
  }

  return (
    <Main activePage={Routes.setup}>
      <aside>
        <SetupMenu selectView={selectView} currentView={state.view} />
      </aside>
      {dataTable()}
    </Main>
  )
}

export default Setup
