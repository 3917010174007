// React
import React, { useState } from 'react'

// Components
import Main from '../layouts/Main'
import { AdminSearchCard } from '../components/AdminSearchCard'
import { LiveMenu } from '../components/LiveMenu'

// Types
import { LiveViews } from '../types'


import { Routes } from '../urls/router-paths'

const Metrics = () => {
  const [state, setState] = useState({ view: LiveViews.store })

  // Set selected view for the Setup page
  const selectView = (view: LiveViews) => {
    return () => {
      setState({
        ...state,
        view: view
      })
    }
  }
  return (
    <Main activePage={Routes.metrics}>
      <div className="menu-nav-wrapper">
        <div className="menu-nav">
          <LiveMenu selectView={selectView} currentView={state.view} />
        </div>
      </div>
      <div className="admin-content-wrapper">
        <div className="admin-page">
          {' '}
          <AdminSearchCard />
        </div>{' '}
      </div>
    </Main>
  )
}

export default Metrics
