export interface HasId {
  id: string
}

export interface Store extends HasId {
  name: string
  group_id: string
  group_name: string
  worker_count: number
  timezone: string
  active: boolean
}

export interface StoreGroup extends HasId {
  name: string
  active: boolean
  manual_stores: boolean
  manual_date: boolean
  flour_fields: string[]
}

export interface StoreDataResponse {
  [key: string]: Store
}

export interface StoreGroupDataResponse {
  [key: string]: StoreGroup
}

export interface UploadSheetNamesResponse {
  [key: string]: string[]
}

export interface Impersonator {
  user_id: number
  user_name: string
}

export type APIDataTypes = Store | StoreGroup

export type DateRange = [Date | null, Date | null]

// eslint-disable-next-line @typescript-eslint/ban-types
export type StateType = object

// LiveViews
export enum LiveViews {
  store = 'Store',
  storeGroup = 'Store Group'
}

// Questions Answered
export interface Question {
  id: string
  question_type: string
  question_text: string
}

interface FlourData {
  date: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export interface Flour {
  store: string
  data: FlourData
}

interface HTTPHeader {
  content_type: string | string[]
}

export interface RequestResponse {
  status: number | undefined
  message: string
  headers?: HTTPHeader
}
