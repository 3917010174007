import { HasId } from '../types'


export const toDictionary = <TItem, Value>(
  array: TItem[],
  getKey: (item: TItem) => string,
  getValue: (item: TItem) => Value
): Record<string, Value> => {
  const result: Record<string, Value> = {}
  array.forEach((item) => {
    const key = getKey(item)
    result[key] = getValue(item)
  })
  return result
}

export const toDictionaryById = <TItem extends HasId>(array: TItem[]): Record<string, TItem> => {
  return toDictionary(
    array,
    (item) => item.id,
    (x) => x
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeToUniqueArray = (arrArg: any[]) => {
  return arrArg.flat().filter((elem, pos, arr) => {
    return arr.indexOf(elem) === pos
  })
}
