import React from 'react'
import { GenericDataTable } from './GenericDataTable'

// Types
import { Store } from '../types'

interface StoreDataTableProps {
  data: Store[]
}

export const StoreDataTable = (props: StoreDataTableProps) => {
  const columns = {
    name: 'Name',
    group_name: 'Group',
    timezone: 'Timezone'
  }
  const data = props.data ?? []

  return <GenericDataTable
    columns={columns}
    data={data}
  />
}
