/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { Button, MenuItem } from '@blueprintjs/core'
import { Select, ItemPredicate, ItemRenderer } from '@blueprintjs/select'
import { useDispatch, useSelector } from 'react-redux'
import { INPUT_REDUCER, DATA_REDUCER, RootState, InputSelector, DataSelector } from '../reduxStore'
import { update } from '../reducerGenerator'
import { APIDataTypes } from '../types'

export interface SelectorItem {
  // Unique identifier
  key: string
  // Primary selector display
  display: string
  // Secondary selector display
  label: string
}

export interface ItemMapper {
  (data: APIDataTypes): SelectorItem
}

export interface SelectorProps {
  itemMapper: ItemMapper
  dataSelector: DataSelector
  inputSelector: InputSelector
  buttonLabel?: string
}

const CommonItemSelect = Select.ofType<SelectorItem>()

const filterItem: ItemPredicate<SelectorItem> = (query, item) => {
  const filtered = item.display.toLowerCase().indexOf(query.toLowerCase()) >= 0
  return filtered
}

export const Selector = (props: SelectorProps) => {
  const { itemMapper, dataSelector, inputSelector, buttonLabel } = props
  const dispatch = useDispatch()
  const data = useSelector((state: RootState) => state[DATA_REDUCER][dataSelector])
  const selections = useSelector((state: RootState) => state[INPUT_REDUCER][inputSelector])

  const items: SelectorItem[] = data ? Object.values(data).map((item) => itemMapper(item)) : []
  const selectedItems: SelectorItem[] = selections
    ? Object.values(selections).map((item) => itemMapper(item))
    : []

  const selectItem = (item: SelectorItem) => {
    // Update data store with selection
    dispatch(
      update(INPUT_REDUCER, 'Update selection data', {
        [inputSelector]: { [item.key]: data[item.key] }
      })
    )
  }

  const renderItem: ItemRenderer<SelectorItem> = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    const renderedItem = (
      <MenuItem text={item.display} label={item.label} key={item.key} onClick={handleClick} />
    )
    return renderedItem
  }

  const getActiveItemDisplay = (buttonLabel = 'Select') => {
    if (selectedItems.length === 1) {
      return selectedItems[0].display
    } else if (selectedItems.length === 0) {
      return buttonLabel
    } else {
      return 'Multiple Selected'
    }
  }

  const select = (
    <div className="selector">
      <CommonItemSelect
        items={items}
        itemPredicate={filterItem}
        itemRenderer={renderItem}
        onItemSelect={selectItem}
        noResults={<MenuItem disabled={false} text="No results" />}
      >
        <Button text={getActiveItemDisplay(buttonLabel)} rightIcon="caret-down" />
      </CommonItemSelect>
    </div>
  )

  return select
}
