// React
import React, { useState } from 'react'

// Components
import { IconName, Intent, Menu, MenuItem } from '@blueprintjs/core'

import Main from '../layouts/Main'
import { Routes } from '../urls/router-paths'
import { GenericDataImport } from '../views/Import/GenericDataImport'

enum Views {
  importData = 'Import Data',
}

type SetView = (itemView: Views) => void

interface IntegrationMenuProps {
  setView: SetView
  currentView: Views
}

interface ItemProps {
  itemView: Views
  icon: IconName | JSX.Element
}

interface IntegrationMenuItemProps {
  menu: IntegrationMenuProps
  item: ItemProps
  disabled?: boolean
}

interface IntegrationState {
  view: Views
}

const makeEmojiIcon = (emoji: string, description: string) => {
  return (
    <span role="img" aria-label={description}>
      {emoji}
    </span>
  )
}

const IntegrationMenuItem = (props: IntegrationMenuItemProps) => {
  const { menu, item, disabled = false } = props
  const getIntent = (itemView: Views) => {
    return menu.currentView === itemView ? Intent.PRIMARY : Intent.NONE
  }

  return (
    <MenuItem
      icon={item.icon}
      text={item.itemView}
      onClick={() => menu.setView(item.itemView)}
      intent={getIntent(item.itemView)}
      disabled={disabled}
    />
  )
}

const IntegrationMenu = (menu: IntegrationMenuProps) => {
  const items: ItemProps[] = [
    { itemView: Views.importData, icon: makeEmojiIcon('📥', 'Import') },
  ]

  const menuItems = items.map((item) => {
    return <IntegrationMenuItem key={item.itemView} menu={menu} item={item} />
  })

  return <Menu>{menuItems}</Menu>
}

const Integration = () => {
  const [state, setState] = useState<IntegrationState>({ view: Views.importData })
  // Set selected view for the Integration page
  const setView = (view: Views) => {
    setState({
      ...state,
      view: view
    })
  }

  const IntegrationContent = () => {
    switch (state.view) {
      case Views.importData:
        return <GenericDataImport />
      default:
        return <div />
    }
  }

  return (
    <Main activePage={Routes.integration}>
      <IntegrationMenu setView={setView} currentView={state.view} />
      <IntegrationContent />
    </Main>
  )
}

export default Integration
