// React
import React from 'react'

// Components
import { Menu, MenuItem, MenuDivider, Intent, IconName } from '@blueprintjs/core'
import { StoreSelector } from '../components/StoreSelector'
import { StoreGroupSelector } from '../components/StoreGroupSelector'
import { LiveViews } from '../types'

type LiveSelectView = (itemView: LiveViews) => void

interface LiveMenuProps {
  selectView: LiveSelectView
  currentView: LiveViews
}

interface LiveMenuItemProps {
  currentView: LiveViews
  selectView: LiveSelectView
  itemView: LiveViews
  icon: IconName
  disabled?: boolean
}

const LiveMenuItem = (props: LiveMenuItemProps) => {
  const { currentView, selectView, itemView, icon, disabled = false } = props
  const getIntent = (itemView: LiveViews) => {
    return currentView === itemView ? Intent.PRIMARY : Intent.NONE
  }

  return (
    <MenuItem
      icon={icon}
      text={itemView}
      onClick={() => selectView(itemView)}
      intent={getIntent(itemView)}
      disabled={disabled}
    />
  )
}

export const LiveMenu = (props: LiveMenuProps) => {
  const { currentView } = props

  // Get the selector associated with a view if possible
  const selector = () => {
    switch (currentView) {
      case LiveViews.store:
        return <StoreSelector />
      case LiveViews.storeGroup:
        return <StoreGroupSelector />
      default:
        return <div />
    }
  }

  return (
    <Menu>
      <LiveMenuItem icon="shop" itemView={LiveViews.store} {...props} />
      <LiveMenuItem icon="office" itemView={LiveViews.storeGroup} {...props} />
      <MenuDivider />
      {selector()}
    </Menu>
  )
}
