import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Metrics, Setup } from './routes'
import { getPath, Routes } from './urls/router-paths'
import {
  fetchStoreThunk,
  fetchStoreGroupThunk
} from './thunks'

import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/select/lib/css/blueprint-select.css'

import 'normalize.css'
import './App.css'
import Integration from './routes/Integration'

export const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const loadState = async () => {
      dispatch(fetchStoreThunk())
      dispatch(fetchStoreGroupThunk())
    }

    loadState()
  }, [dispatch])

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={getPath(Routes.setup)} component={Setup} />
        <Route exact path={getPath(Routes.metrics)} component={Metrics} />
        <Route exact path={getPath(Routes.integration)} component={Integration} />
        <Route>
          <Redirect to={getPath(Routes.integration)} />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default App
