// Redux
import { update } from './reducerGenerator'
import {
  DISPLAY_STATE_REDUCER,
  DATA_REDUCER,
  RootState,
  OnarollThunkDispatch,
} from './reduxStore'

// Backend
import {
  fetchStore,
  fetchStoreGroup,
  fetchUploadSheetNames,
  QueryParams
} from './api/backend'

// Types
import {
  StoreDataResponse,
  StoreGroupDataResponse
} from './types'

export const fetchStoreGroupThunk = () => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const storeGroups: StoreGroupDataResponse = await fetchStoreGroup()

    dispatch(
      update(DATA_REDUCER, 'Load fetched store group data', {
        ...state,
        storeGroups
      })
    )
  } catch (error) {
    update(DISPLAY_STATE_REDUCER, 'Show error', {
      dataFetchError: error || 'fetchStoreGroupThunk error'
    })
  }
}

export const fetchUploadSheetNamesThunk = (storeGroupId: QueryParams) => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const uploadSheetNames = await fetchUploadSheetNames(storeGroupId)

    dispatch(
      update(DATA_REDUCER, 'Load fetched upload sheet names', {
        ...state,
        uploadSheetNames
      })
    )
  } catch (error) {
    update(DISPLAY_STATE_REDUCER, 'Show error', {
      dataFetchError: error || 'fetchUploadSheetNamesThunk error'
    })
  }
}

export const fetchStoreThunk = () => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const stores: StoreDataResponse = await fetchStore()

    dispatch(
      update(DATA_REDUCER, 'Load fetched store data', {
        ...state,
        stores
      })
    )
  } catch (error) {
    update(DISPLAY_STATE_REDUCER, 'Show error', {
      dataFetchError: error || 'fetchStoreThunk error'
    })
  }
}
