import {
  Flour,
  Store,
  StoreDataResponse,
  StoreGroup,
  StoreGroupDataResponse,
  UploadSheetNamesResponse,
} from '../types'
import { debounce } from 'lodash'

import { getApiHost } from '../urls/router-paths'
import { toDictionaryById } from '../utils'
const API_PATH = '/admin/dashboard/api/v1/'

enum ENDPOINTS {
  store = 'store',
  flour = 'flour',
  storeGroup = 'storegroup',
  csrf = 'csrf',
  uploadSheetNames = 'integration/upload-sheet-names',
}

type ApiCall<T> = () => Promise<T>

export class HttpError extends Error {
  status: number
  url: string
  constructor(message: string, status: number, url: string) {
    super(message)
    this.name = 'HttpError'
    this.message = message
    this.status = status
    this.url = url
  }
}

export type QueryParams = Record<string, string | string[] | number | number[]>

const apiHost = getApiHost()

const _get = async (url_path: string, query_params?: QueryParams) => {
  const param_str = query_params
    ? `?${Object.keys(query_params)
        .map((key) => key + '=' + query_params[key])
        .join('&')}`
    : ''

  const result = await fetch(`${apiHost}${API_PATH}${url_path}${param_str}`, {
    mode: 'cors',
    credentials: 'include'
  })
  if (!result.ok) {
    throw new HttpError(result.statusText, result.status, result.url)
  }
  return await result.json()
}

export const fetchCsrf = async () => {
  try {
    const csrf: { token: string } = await _get(ENDPOINTS.csrf)
    return csrf.token
  } catch (e) {
    if (e.status === 404) {
      // This is fine: no data found
      return ''
    }
    throw e
  }
}

export const post = async <T>(url_path: string, body: T) => {
  const token = await fetchCsrf()

  const result = await fetch(`${apiHost}${API_PATH}${url_path}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': token
    },
    body: JSON.stringify(body)
  })
  if (!result.ok) {
    let errorMessage: string

    try {
      const error = await result.text()
      errorMessage = error
    } catch (e) {
      errorMessage = String(e)
    }

    throw new HttpError(errorMessage, result.status, result.url)
  }
  try {
    return await result.json()
  } catch (e) {
    return result
  }
}

export const postForm = async (url_path: string, form: FormData) => {
  const token = await fetchCsrf()

  const result = await fetch(`${apiHost}${API_PATH}${url_path}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'X-CSRFToken': token
    },
    body: form
  })
  if (!result.ok) {
    let errorMessage: string

    try {
      const error = await result.text()
      errorMessage = error
    } catch (e) {
      errorMessage = String(e)
    }

    throw new HttpError(errorMessage, result.status, result.url)
  }
  try {
    return await result.json()
  } catch (e) {
    return result
  }
}

/**
 * delay posting form data to the backend by 3 seconds to avoid possible race conditions that might cause data to be duplicated.
 * delaying the API call would allow for atleast one API request to be processed at a time from the backend which would allow validation
 * to run properly for adjascent requests.
 **/
export const debouncedFormPost = debounce(postForm, 3000)

export const fetchStoreGroup: ApiCall<StoreGroupDataResponse> = async () => {
  try {
    const storeGroups: StoreGroup[] = await _get(ENDPOINTS.storeGroup)
    const result: StoreGroupDataResponse = toDictionaryById(storeGroups)
    return result
  } catch (e) {
    if (e.status === 404) {
      // This is fine: no data found
      return {}
    }
    throw e
  }
}

export const fetchStore: ApiCall<StoreDataResponse> = async () => {
  try {
    const stores: Store[] = await _get(ENDPOINTS.store)
    const result: StoreDataResponse = toDictionaryById(stores)
    return result
  } catch (e) {
    if (e.status === 404) {
      // This is fine: no data found
      return {}
    }
    throw e
  }
}

export const fetchUploadSheetNames = async (storeGroupId: QueryParams) => {
  const response: UploadSheetNamesResponse = await _get(ENDPOINTS.uploadSheetNames, storeGroupId)
  return response
}

export const postFlour = async (flour: Flour) => await post(ENDPOINTS.flour, flour)
