import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { Reducer, Action } from 'redux'
import thunk, { ThunkDispatch } from 'redux-thunk'

import { createLogger } from 'redux-logger'

import { createReducer } from './reducerGenerator'
import {
  StoreDataResponse,
  StoreGroupDataResponse,
  DateRange,
  UploadSheetNamesResponse,
} from './types'

// Manage transient states like loading and success responses
export const DISPLAY_STATE_REDUCER = 'display'
type DisplayStateReducer = Reducer<{
  dataFetchError: boolean
  isLoading: boolean
}>
const defaultDisplayStateReducer = {
  dataFetchError: false,
  isLoading: false
}

// Manage stateful data fetched from the backend
export const DATA_REDUCER = 'data'
export enum DataSelector {
  stores = 'stores',
  storeGroups = 'storeGroups',
  questionsAnswered = 'questionsAnswered',
  uploadSheetNames = 'uploadSheetNames',
}
type DataReducer = Reducer<{
  [DataSelector.stores]: StoreDataResponse
  [DataSelector.storeGroups]: StoreGroupDataResponse
  [DataSelector.uploadSheetNames]: UploadSheetNamesResponse
}>
const defaultDataReducer = {
  [DataSelector.stores]: {},
  [DataSelector.storeGroups]: {},
  [DataSelector.uploadSheetNames]: {},
}

// Generate the current date and 7 days ago in UTC
const initializeDate = (): DateRange => {
  const now = new Date()
  now.setUTCHours(0, 0, 0, 0)
  now.setDate(now.getDate() + 1)
  const weekAgo = new Date()
  weekAgo.setUTCHours(0, 0, 0, 0)
  weekAgo.setDate(weekAgo.getDate() - 6)
  return [weekAgo, now]
}

// Manage stateful user input data
export const INPUT_REDUCER = 'input'
export enum InputSelector {
  stores = 'stores',
  storeGroups = 'storeGroups',
  dateRange = 'dateRange',
}
type InputReducer = Reducer<{
  [InputSelector.stores]: StoreDataResponse
  [InputSelector.storeGroups]: StoreGroupDataResponse
  [InputSelector.dateRange]: DateRange
}>

const defaultInputReducer = {
  [InputSelector.stores]: {},
  [InputSelector.storeGroups]: {},
  [InputSelector.dateRange]: initializeDate()
}

const reducers = {
  [INPUT_REDUCER]: createReducer(INPUT_REDUCER, defaultInputReducer) as InputReducer,
  [DISPLAY_STATE_REDUCER]: createReducer(
    DISPLAY_STATE_REDUCER,
    defaultDisplayStateReducer
  ) as DisplayStateReducer,
  [DATA_REDUCER]: createReducer(DATA_REDUCER, defaultDataReducer) as DataReducer,
}

const middlewares = [thunk, createLogger()]

const combinedReducers = combineReducers(reducers)

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const reduxStore = createStore(
  combinedReducers,
  composeEnhancers(applyMiddleware(...middlewares))
)

export type RootState = ReturnType<typeof combinedReducers>

export type OnarollThunkDispatch = ThunkDispatch<RootState, void, Action>
