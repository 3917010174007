/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from 'react'
import { Table, Column, ColumnHeaderCell, Cell } from '@blueprintjs/table'

// Components
import { Menu, MenuItem } from '@blueprintjs/core'

// Styles
import '@blueprintjs/table/lib/css/table.css'

export interface DataTableProps<T> {
  // Data key mapping to column name values for rendering
  // eslint-disable-next-line @typescript-eslint/ban-types
  columns: object
  data: T[]
  referenceColumns?: {
    [key: string]: string
  }
}

interface ColumnFilters {
  [key: string]: string
}

interface DataTableState<T> {
  items: T[]
  columnFilters: ColumnFilters
}

export const GenericDataTable = <T,>(props: DataTableProps<T>) => {
  const { columns, referenceColumns, data } = props

  const columnKeys = Object.keys(columns)
  const columnValues = Object.values(columns)

  const [state, setState] = useState<DataTableState<T>>({
    items: [],
    columnFilters: columnKeys.reduce((acc: ColumnFilters, key) => {
      acc[key] = ''
      return acc
    }, {})
  })

  useEffect(() => {
    const loadState = async () => {
      if (data) {
        setState((currentState) => {
          return {
            ...currentState,
            items: data
          }
        })
      }
    }
    loadState()
  }, [data])

  const menuRenderer = (columnIndex?: number) => {
    if (!columnIndex) {
      return <Menu></Menu>
    }
    const columnKey = columnKeys[columnIndex]
    const sortAsc = () => {
      const sorted = data.sort((a, b) => {
        // @ts-ignore
        if (a[columnKey] === b[columnKey]) {
          return 0
        }
        // @ts-ignore
        return a[columnKey] > b[columnKey] ? 1 : -1
      })
      setState({
        ...state,
        items: sorted
      })
    }

    const sortDesc = () => {
      const sorted = data.sort((a, b) => {
        // @ts-ignore
        if (a[columnKey] === b[columnKey]) {
          return 0
        }
        // @ts-ignore
        return a[columnKey] < b[columnKey] ? 1 : -1
      })
      setState({
        ...state,
        items: sorted
      })
    }

    // Filter data by lowercase string comparison
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterChange = (value: any) => {
      const filters: ColumnFilters = {
        ...state.columnFilters,
        [columnKey]: value
      }

      // Apply all existing filters across all columns
      const filtered = data.filter((item) => {
        return Object.entries(filters).every(([key, value]) => {
          // Return early if no filter is defined
          if (value.length === 0) {
            return true
          }
          // Extract value of cell
          // @ts-ignore
          const itemValue = item[key]
          if (Array.isArray(itemValue)) {
            // Skip array if no data is defined
            if (itemValue.length === 0) {
              return false
            }
            // Compare lowercase strings for each referenced field in the object array
            // Only some values need to be true for the filter to apply
            return itemValue.some((subitem) => {
              return referenceColumns
                ? String(subitem[referenceColumns[key]]).toLowerCase().includes(value.toLowerCase())
                : false
            })
            // Compare lowercase strings for non-array data types
          } else {
            // @ts-ignore
            return String(item[key]).toLowerCase().includes(value.toLowerCase())
          }
        })
      })

      // Set the column filter state to the current input value
      setState({
        ...state,
        items: filtered,
        columnFilters: {
          ...state.columnFilters,
          [columnKey]: value
        }
      })
    }

    return (
      <Menu>
        <input
          className="bp3-input bp3-fill"
          type="text"
          placeholder="Filter"
          onChange={filterChange}
          defaultValue={state.columnFilters[columnKey]}
        />
        <MenuItem icon="sort-asc" onClick={sortAsc} text="Sort Asc" />
        <MenuItem icon="sort-desc" onClick={sortDesc} text="Sort Desc" />
      </Menu>
    )
  }

  const columnHeaderCellRenderer = (columnIndex: number) => {
    const columnKey = columnKeys[columnIndex]
    const style = state.columnFilters[columnKey] !== '' ? 'column-header-highlight' : ''

    return (
      <ColumnHeaderCell
        name={columnValues[columnIndex]}
        index={columnIndex}
        menuRenderer={menuRenderer}
        className={style}
      />
    )
  }

  // @ts-ignore
  const cellRenderer = (rowIndex, columnIndex) => {
    const key = columnKeys[columnIndex]

    if (state.items) {
      const item = state.items[rowIndex]
      // @ts-ignore
      let value = item[key]

      // Render array data in a single cell
      if (Array.isArray(value) && referenceColumns && referenceColumns[key]) {
        const valueLength = value.length
        // Render the number of values that exist
        if (valueLength > 1) {
          const columnName = columnValues[columnIndex]
          // Limit total rendered
          const valueLimit = 100
          const limitedLength = valueLength < valueLimit ? valueLength : `${valueLimit}+`
          value = `${limitedLength} ${columnName}`
        } else if (valueLength === 1) {
          value = value[0][referenceColumns[key]]
        } else {
          value = ''
        }
        // Replace value with referenced column value if one is defined
      } else if (value && referenceColumns && referenceColumns[key]) {
        // Support field options;
        // Returns the options that it finds;
        value = value[referenceColumns[key]]
      }

      if (typeof value === 'boolean') {
        value = value === true ? '✅' : '❌'
      }

      if (key === 'image' && value) {
        value = <img key={`${rowIndex}-${columnIndex}`} src={value} className="image-cell" alt="" />
      }

      return (
        <Cell key={`${rowIndex}-${columnIndex}`}>
          {value}
        </Cell>
      )
    }
    return <Cell key={`${rowIndex}-${columnIndex}`} loading={true} />
  }

  const generateColumn = (columnName: string) => {
    return (
      <Column
        key={columnName}
        name={columnName}
        columnHeaderCellRenderer={columnHeaderCellRenderer}
        cellRenderer={cellRenderer}
      />
    )
  }

  // Default to rendering 10 rows if data hasn't completed loading
  const rows = state.items ? state.items.length : 10
  const table = (
    <div className={'table'} style={{ width: "100%" }}>
      <Table numRows={rows} columnWidths={Array(3).fill(700)}>
        {columnValues.map((columnName) => {
          return generateColumn(columnName)
        })}
      </Table>
    </div>
  )

  return table
}
